import(/* webpackMode: "eager" */ "/app/.pnpm/next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opentelemetry+api@1.9.0_kc3mbx5rlm3t7mkfz7ycpcxtj4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/.pnpm/next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opentelemetry+api@1.9.0_kc3mbx5rlm3t7mkfz7ycpcxtj4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/HomePageGNB/ConditionalButtonsByLogin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/HomePageGNB/HamburgerMenu/HamburgerMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/HomePageGNB/SupportButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/shared/ui/language/language-select-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownRoot"] */ "/app/packages/hits-ui/src/dropdown/round.dropdown.tsx");
